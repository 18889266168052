import React from 'react'

export default function CartEvalon() {
  return (
	<div>
		<h2>cartEvalon</h2>
		{/* <form action="https://api.convergepay.com/VirtualMerchant/processxml.do" method="post" acceptCharset="utf-8">
			<input type="text" name="ssl_merchant_id" value="2331212" />
			<input type="text" name="ssl_user_id" value="8040239397web" />
			<input type="text" name="ssl_pin" value="M8MH739WBFNE5T0M3SEYO09JMND29ZQC3ZXEN252DJPX36HBG8MEV838VZJH2Z9E" />
			<input type="text" name="ssl_test_mode" value="false" />
			<input type="text" name="ssl_transaction_type" value="ccsale" />
			<input type="number" name="ssl_card_number" value="4724090452346172" />
			<input type="number" name="ssl_exp_date" value="0125" />
			<input type="number"name="ssl_amount" value="1.00" />
			<button type="submit">submit</button>
		</form> */}
		<form action="http://localhost:3004/request_session_token_demo.php" name="test1" method="post">
        <table border="1" bordercolorlight="#C0C0C0" cellspacing="5" bordercolordark="#FFFFFF">
            <tr>
                <td>Trans Amount</td>
                <td><input type="text" name="ssl_amount" size="6" value="1.00"/></td>
            </tr>
            <tr>
                <td>Transaction Type</td>
                <td>
                    <select size="1" name="ssl_transaction_type">
                        <option value="CCSALE">Credit Card Sale</option>
                        <option value="CCAUTHONLY">Credit Card Authorization Only</option>
                        <option value="CCVerify">Credit Card Verification </option>
                        <option value="CCGETTOKEN">Credit Card Get Token</option>
                        <option value="SALE">Generic Sale</option>
                        <option value="AUTHONLY">Generic Authorization Only</option>
                        <option value="ecspurchase">ACH</option>
                        <option value="EGCSALE">Gift Card</option>
                    </select>
                </td>
            </tr>
            <tr>
                <td colspan="2">&nbsp;</td>
            </tr>
            <tr>
                <td colspan="2">
                    <p align="center">Redirect to NG HPP</p>
                </td>
            </tr>
            <tr>
                <td colspan="2" align="center">
                    <input type="submit" value="Pay Now" name="Submit"/>
                </td>
            </tr>
        </table>
    </form>



	</div>
  )
}
