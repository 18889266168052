import React, { useEffect, useContext } from 'react'
import { CartContext } from '../config/CartContext';
import Aos from "aos";
import '../css/Home.css'
import "aos/dist/aos.css";
import { Link } from 'react-router-dom';
import {WebData} from '../config/cartData';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import {useSpring, animated} from 'react-spring';

export default function LandingPage() {
	const { addItemToCart } = useContext(CartContext);
	useEffect(()=>{
		
		Aos.init({duration: 1200});
	},[]);
	const props = useSpring({
		config: { duration:1000},
		from:{ opacity: 0},
		to:{ opacity: 1}})
		

  return (
	<body>
		<div className='image-container'>
				<LazyLoadImage alt="" src='https://res.cloudinary.com/kcb-software-design/image/upload/v1650418341/Wellness%20Supplements/waterfall-with-well-and-old-trunk-2021-08-26-17-01-59-utc_rzzrtx.jpg' className="square"/>
			{/* <img className="square" src={Picture} alt=""></img> */}
		<animated.div style={props} className='big-font-top'>
		Welecome to <br/>Wellness Supplements<br/>
		</animated.div>
			<div className="container-block"><animated.div style={props} className='smalla'>
					 here to help you make wellness a priority
				</animated.div></div>
			</div>
		<div className= "container-whole">
			<div className="about-container">
				<div className="aboutTitle">Who Are We?</div>
				<div className="aboutDetails"> Wellness Supplements is your all in one marketplace for all natural supplements. We provide top of the line health supplements to increase each customers quality of life. We have remedies for a plethora of health concerns you may have. We specialize in multiple-ingredient encapsulated and powder formulations (free-from, vegan and vegetarian), herbal formulas, plant-based protein powders and health foods. We are dedicated to making sure our customers feel fulfilled, replenished and ready to experience their life to their full potential.</div>
				<LazyLoadImage className='pickingImage' alt="mushrooms" src='https://res.cloudinary.com/kcb-software-design/image/upload/v1649903410/UsNatural/plant-2021_tg8scf.jpg'/>
			</div>
					<div className='productContainer'>
				<div className="sale">PRODUCTS</div>
						<div className="itemsContainer">
							{WebData.map((item, index)=>{
								return(
									<div key={index} className="individual-container">
										<li key={index} className='names'>
										<Link style={{ textDecoration: 'none', color: 'black', }} to={{pathname:'/productDetails', state: item}} >
											<img  className="listimage"src={item.image} alt=''/>
											<div className="productLandingName">{item.name}</div>
											<div className="price">${item.price} USD</div>
									</Link>
											<button className="addTo" onClick={()=> addItemToCart(item.id)}> ADD TO CART</button>
										</li>
									</div>
								)
							})}
						</div>
		
					</div>
		</div>

	</body>
  )
}
