import React from 'react'
import {WebData} from '../config/cartData';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import '../css/products.css';
import Footer from '../components/Footer';

export default function Products() {
  return (
	<div className='image-container productImage'>
				<LazyLoadImage alt="" src='https://res.cloudinary.com/kcb-software-design/image/upload/v1644976040/App4u/products_ynjgxu.jpg' className=" square productImage"/>
			{/* <img className="square" src={Picture} alt=""></img> */}
				<div className="sale">Websites For Sale:</div>
			<div className='productContainer'>
				{WebData.map((item, index)=>{
					return(
						<li key={index} className={item.name}>
							<h1>{item.name}</h1>
							<span>${item.price}</span><br/>
							<img  className="listimage"src={item.image} alt=''/>

						</li>
					)
				})}

			</div>
			<Footer/>
		</div>
  )
}
