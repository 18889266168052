import React, { useState, useEffect } from 'react';
import "../css/contact.css";
import { getFunctions, httpsCallable} from 'firebase/functions';
import {app} from '../config/firebase.js';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css'
const firebaseFunctions = getFunctions(app,'us-central1');


export default function Contact() {
	const [ValidErr, setRaiseErr] = useState({});
	const [ValidSend, setSentMessage] = useState({});
	const [Phonevalue, setPhoneValue] = useState();
	let NameInput = React.createRef();
	let EmailInput = React.createRef();
	let Comment = React.createRef(); 


	const formValidation = () => {
		console.log(Phonevalue);
		let isValid = true;
		const ValidErr = {};
		
		if (NameInput.current.value === "" || EmailInput.current.value === ""  || Phonevalue === ""  || Comment.current.value === "" ) 
		{
			ValidErr.RequiredFieldsEmpty = "You Must Fill In All Required Fields"
				isValid = false;
				setRaiseErr(ValidErr);
			}
			
			return isValid;
	
		
	}
	const onSubmit = (e) => {
		e.preventDefault();
		const isValid = formValidation();
		if(isValid)
		{	
			// const SuccessErr = {};
			//  SuccessErr.RequiredFieldsEmpty = "Successfully sent. We will get back to you shortly"
			//  setSuccessErr(SuccessErr);
			const sendEmail = httpsCallable(firebaseFunctions, "sendEmail", app);
			 sendEmail({

				name : NameInput.current.value,
				email :EmailInput.current.value,
				phone : Phonevalue,
				comment : Comment.current.value,
				// time : Date.,
			}).then(result =>{
							console.log("email sending result:" + result);
						}
					).catch(error =>{
							console.log(error);
							console.log("frontend error");
						})

		NameInput.current.value ="";
		EmailInput.current.value ="";
		setPhoneValue("");
		Comment.current.value ="";
		alert("Your Message has been sent and we will contact you within 24 hours!");
		}

	}
  return (
	<body>
		<div className="start-text">Fill out this contact form to contact us:</div><br/>
		<div id="Contact" className="email-container">
				<div className= "contactTitle">Contact Us Directly</div>
				<form className= "form" onSubmit={onSubmit}>
				<label className="questions"> Full Name*</label><input ref={NameInput}></input><br/>
				<label  className="questions">Email*</label><input ref={EmailInput}></input><br/>
				<label  className="questions">Phone Number*</label><PhoneInput className="phoneInput" defaultCountry="US" value={Phonevalue} placeholder="Phone Number" name="phone" onChange={setPhoneValue} /><br/>
			
				<label  className="questions">Tell Us About Your Needs</label><br/><textarea className="commentInput"ref={Comment}></textarea><br/>
				{Object.keys(ValidErr).map((key) => {
					return <div style={{ color: "red" }}>{ValidErr[key]}</div>
				})}
				{Object.keys(ValidSend).map((key) => {
					return <div style={{ color: "green" }}>{ValidSend[key]}</div>
				})}
				<button  className="submitButton" type="submit">Submit</button>
				</form>
				sales.usnatural@gmail.com<br/>
				587-888-3825<br/>
				1333 Haro Street Vancouver BC<br/>
						
			</div>
	</body>
  )
}
