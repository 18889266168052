import * as firebase from 'firebase/app'
import React, { useEffect, useState, useContext } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { Link } from 'react-router-dom';
import { getDatabase, ref, child, get } from 'firebase/database';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { initializeApp } from "firebase/app";
import { loadStripe } from '@stripe/stripe-js';
import { CardElement, Elements, useStripe, useElements, } from '@stripe/react-stripe-js';
// import { getAnalytics } from "firebase/analytics";
import { CartContext } from '../config/CartContext';
import { app } from '../config/firebase';
import '../css/cart.css';


const firebaseFunctions = getFunctions(app);
const databaseR = getDatabase(app);
export default function Cart() {
	const { items, setItems, getItemsCount, getTotalPrice, addItemToCart, removeItemFromCart } = useContext(CartContext);
	const [key, setKey] = useState("");
	const [gotKey, setGotKey] = useState(false);
	const [stripeItems, setStripeItems] = useState(items.map((item) => {
		return (
			{
				price: item.product.priceID,
				quantity: item.qty,
			})
	}));


	useEffect(() => {
		if (gotKey == false) {
			firebasekey();
		}
	}, [])
	const firebasekey = () => {
		const getKey = httpsCallable(firebaseFunctions, "key", app);
		getKey().then(result => { setKey(result.data); setGotKey(true) }).catch(error => console.log(error));
	}
	const forLoopFunction = async (_callback) => {

		fetch('http://localhost:3003/book',{
			method: 'POST',
			headers:{'Content-Type': 'application/json' },
			body:JSON.stringify({name: "YourMom"})
			
		}).then((res)=>{
			console.log(res)
		}).catch((error)=>{
			console.log(error);
		})
		// if (items.length >= 1) {
		// 	items.map((item) => {
		// 		const existingCartItemIndex = stripeItems.findIndex((i) => (i.price == item.product.priceID));
		// 		if (stripeItems.length == 0 || existingCartItemIndex < 0) {
		// 			setStripeItems((prevItems) => {
		// 				return [
		// 					...prevItems, {
		// 						price: item.product.priceID,
		// 						quantity: item.qty,
		// 					}
		// 				];
		// 			})
		// 		}

		// 		else {

		// 			setStripeItems((prevItems) => {

		// 				return prevItems.map((i,) => {
		// 					if (i.id) {
		// 						setStripeItems(stripeItems.filter(y => y.id !== i.id));
		// 					}
		// 					if (i.price == item.product.priceID) {
		// 						i.quantity = item.qty;

		// 					}
		// 					return item;
		// 				});
		// 			});
		// 		}
		// 	})
		// 	if (Object.keys(stripeItems).length > 0) {
		// 		onCheckout();
		// 	}
		// }
	}



	const onCheckout = async (e) => {

		const stripePromise = await loadStripe(key);
		const checkout = httpsCallable(firebaseFunctions, "stripeFunction", app);
		checkout({
			cartItems: stripeItems,

		}).then(result => {
			const sessionId = result.data.id;
			stripePromise.redirectToCheckout({ sessionId: sessionId });
			clearCart();

		}
		).catch(error => {
			console.log(error);
			console.log("frontend error");

		})
	}

	const clearCart = () => {
		setItems([]);
		setStripeItems([]);
		localStorage.clear();
	}
	const handleChange = (action, iItem) => {

		const existingCartItemIndex = stripeItems.findIndex((item) => (item.price == iItem.product.priceID));
		const existingItem = stripeItems.find((item) => (item.price == iItem.product.priceID));
		if (action == 'remove') {
			removeItemFromCart(iItem.id)

		}
		if (action == 'add') {
			addItemToCart(iItem.id)

		}

		setStripeItems((prevItems) => {
			return prevItems.map((item,) => {
				if (item.price == iItem.product.priceID) {
					;
					item.quantity = iItem.qty;
				}
				if (item.quantity <= 0 || item.id) {
					setStripeItems(stripeItems.filter(item => item.price !== iItem.product.priceID));
				}
				return item;
			});
		});

		//	}



	}

	return (
		<body>
			<div className="bigContainer">
				<LazyLoadImage alt="" src='https://res.cloudinary.com/kcb-software-design/image/upload/v1649900268/UsNatural/natural-zomed-in-2022-03-01-02-17-35-utc_t8phmt.jpg' className="square" />
				<h1 style={{ color: 'white' }}>Cart</h1>
				<div className="cartContainer">
					<div className="displayContainer">
						<div className='itemText'>ITEMS</div>
						<div className='qtyText'>QTY</div>
						<div className='totalText'>TOTAL</div>
					</div>
					<div className="line"></div>
					<div className="cartList">
						{
							items.map((item, index) => {

								if (item.id != null) {
									return (
										<li key={index}>
											<div className="cartLine" style={{ color: 'white' }}>
												<div className="nameRow">
													<img className="img" src={item.product.image} alt="" />
													<div className="nameCart"> {item.product.name}</div>
												</div>

												<div className='quantityRow'>
													<button className="remove" onClick={() => handleChange('remove', item)}>-</button>
													<div className='quantity'>x{item.qty}</div>
													<button className="remove" onClick={() => handleChange('add', item)}>+</button>
												</div>
												<span className="totalIndividual">{(item.totalPrice).toFixed(2)}</span>
											</div>
											<div className="line adjust"></div>
										</li>
									)

								}
							})}

						<div className='totals'>Total: ${getTotalPrice().toFixed(2)} USD</div>
						<Link className="checkoutButton cartbuttons" to={{pathname:'/checkout', items: items, total: getTotalPrice().toFixed(2)}}>Proceed To Checkout</Link>
						{/* <button className="checkoutButton cartbuttons" onClick={() => forLoopFunction()}>Proceed To Checkout</button> */}
						<button className="clearButton cartbuttons" onClick={() => clearCart()}>Clear Cart</button>
					</div>
				</div>
			</div>

		</body>
	)
}
//}
