import React, { useRef, Component } from 'react'
// import Autocomplete from "react-google-autocomplete";
// import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import Cards from 'react-credit-cards';
import '../css/checkout.css'
import 'react-credit-cards/es/styles-compiled.css';
// import { useLocation } from 'react-router-dom';

export default class Checkout extends Component {
	//  static location = useLocation()
	constructor(props){
		super(props)
		this.autoCompleteRef = React.createRef();
		this.autoCompleteBillingRef = React.createRef();
		this.inputRef  = React.createRef();
		this.inputBillingRef  = React.createRef();
		this.state={
			cvc: '',
			expiry: '',
			focus: '',
			name: '',
			number: '',
			shippingAddress: '',
			billingAddress: '',
			shippingPostal: '',
			billingPostal: '',
			items: props.location.items,
			total: props.location.total,
			options: {
				fields: ["address_components", "geometry", "icon", "name"],
				types: ["address"],
			   },
		}
	}
	componentDidMount =() =>{
		this.autoCompleteRef.current = new window.google.maps.places.Autocomplete(
			this.inputRef.current,
			this.state.options
		   );
		this.autoCompleteBillingRef.current = new window.google.maps.places.Autocomplete(
			this.inputBillingRef.current,
			this.state.options
		   );
		//    console.log(this.autoCompleteRef.current);


	}
	handleChange=(event)=> {

		const target = event.target;
		const value = target.value;
		const name = target.name;
		this.setState({[name]: value});
	  }
	  handleInputFocus = (e) => {
		this.setState({ focus: e.target.name });
	  }
	  
	  handleInputChange = (e) => {
		const { name, value } = e.target;

		this.setState({ [name]: value });
	  }
	  handleMap = (e)=>{
		if(e == "shippingAddress"){
			this.autoCompleteRef.current = new window.google.maps.places.Autocomplete(
				this.inputRef.current,
				this.state.options
			   );
		}
		if(e == "billingAddress"){
			this.inputBillingRef.current = new window.google.maps.places.Autocomplete(
				this.inputRef.current,
				this.state.options
			   );
		}

	  }
	  Submit =(e)=>{
		fetch('http://localhost:3005/book',{
			method: 'POST',
			headers:{'Content-Type': 'application/json' },
			body:JSON.stringify(
				{
					number:this.state.number,
					expiry: this.state.expiry,
					cvc: this.state.expiry,
					name: this.state.name,
					shippingAddress: this.state.shippingAddress,
					billingAddress: this.state.billingAddress,
					shippingPostal: this.state.shippingPostal,
					billingPostal: this.state.billingPostal,
					items: this.state.items,
					total: this.state.total,
				})
			
		}).then((res)=>{
			console.log(res)
		}).catch((error)=>{
			console.log(error);
		})
	  }
  render() {
	return (
	  <div className="page">
		<div id="PaymentForm" className="page">
		<img style={{maxWidth:"200px"}}src="https://res.cloudinary.com/kcb-software-design/image/upload/v1666848316/cc_yhw6nf.png" alt="credit card logos"/><br/>
		{/* <b style={{'marginBottom': "20px", "marginTop": '60px'}}>Fillout the information</b> */}
        <Cards
          cvc={this.state.cvc}
          expiry={this.state.expiry}
          focused={this.state.focus}
          name={this.state.name}
          number={this.state.number}
		  
        />
        <form  style={{'display':"flex", "flexDirection":"column", "marginTop": '40px'}} >
        	<b>Total: ${this.state.total} USD</b><br/>
			Your package will take anywhere from 3-14 days to reach it's destination<br/>
			<b style={{'marginBottom': "20px", "marginTop": '20px'}}>Fillout the information below</b>
			<input
            type="text"
            name="name"
            placeholder="Full Name"
            onChange={this.handleInputChange}
            onFocus={this.handleInputFocus}
          />
        	<input
            type="number"
            name="number"
            placeholder="Card Number"
            onChange={this.handleInputChange}
			onInput={(event)=>event.target.value=event.target.value.slice(0,event.target.maxLength)}
            onFocus={this.handleInputFocus}
			maxLength='16'
          />
        	<input
            type="number"
            name="expiry"
            placeholder="Expiry"
            onChange={this.handleInputChange}
            onFocus={this.handleInputFocus}
			onInput={(event)=>event.target.value=event.target.value.slice(0,event.target.maxLength)}
			maxLength="4"
          />
        	<input
            type="number"
            name="cvc"
            placeholder="CVC"
            onChange={this.handleInputChange}
            onFocus={this.handleInputFocus}
			onInput={(event)=>event.target.value=event.target.value.slice(0,event.target.maxLength)}
			maxLength="3"
          />
		  <label>Use autocomplete to input your <u>Shipping</u> address :</label>
   <input name="shippingAddress"onInput={(event)=>this.setState({shippingAddress: event.target.value})} ref={this.inputRef} onChange={(e)=>this.handleMap(e.target.name)}/>
	
		  <input
            type="text"
            name="shippingPostal"
            placeholder="Shipping Postal/Zip Code"
            onChange={this.handleInputChange}
          />
		  <label>Use autocomplete to input your <u>Billing</u> address :</label>
		  <input name="billingAddress" onInput={(event)=>this.setState({billingAddress: event.target.value})} ref={this.inputBillingRef} onChange={(e)=>this.handleMap(e.target.name)}/>
		  <input
            type="text"
            name="billingPostal"
            placeholder="Billing Postal Code"
            onChange={this.handleInputChange}
          />

		<button onClick={this.Submit}>Submit Payment</button>
        </form>
      </div>
	  </div>
	)
  }
}
