import React from 'react';
import '../css/terms.css';

export default function Terms() {
  return (
	<div className="termsBody">
		TERMS AND CONDITIONS<br/><br/>

These terms and conditions (the "Terms and Conditions") govern the use of wellness-supplements.ca (the "Site"). This Site is owned and operated by Wellness Supplements. This Site is an ecommerce website.<br/><br/>

By using this Site, you indicate that you have read and understand these Terms and Conditions and agree to abide by them at all times.<br/><br/>

THESE TERMS AND CONDITIONS CONTAIN A DISPUTE RESOLUTION CLAUSE THAT IMPACTS YOUR RIGHTS ABOUT HOW TO RESOLVE DISPUTES. PLEASE READ IT CAREFULLY.<br/><br/>

Intellectual Property<br/>
All content published and made available on our Site is the property of Wellness Supplements and the Site's creators. This includes, but is not limited to images, text, logos, documents, downloadable files and anything that contributes to the composition of our Site.<br/><br/>

Age Restrictions<br/>
The minimum age to use our Site is 19 years old. By using this Site, users agree that they are over 19 years old. We do not assume any legal responsibility for false statements about age.<br/><br/>

Acceptable Use<br/>
As a user of our Site, you agree to use our Site legally, not to use our Site for illegal purposes, and not to:<br/><br/>

Harass or mistreat other users of our Site;<br/>
Violate the intellectual property rights of the Site owners or any third party to the Site;<br/>
Hack into the account of another user of the Site;<br/>
Act in any way that could be considered fraudulent; or<br/>
Post any material that may be deemed inappropriate or offensive.<br/>
If we believe you are using our Site illegally or in a manner that violates these Terms and Conditions, we reserve the right to limit, suspend or terminate your access to our Site. We also reserve the right to take any legal steps necessary to prevent you from accessing our Site.<br/><br/>

Sale of Goods<br/>
These Terms and Conditions govern the sale of goods available on our Site.<br/><br/>

The following goods are available on our Site:<br/><br/>

Health Products.<br/>
These Terms and Conditions apply to all the goods that are displayed on our Site at the time you access it. This includes all products listed as being out of stock. All information, descriptions, or images that we provide about our goods are as accurate as possible. However, we are not legally bound by such information, descriptions, or images as we cannot guarantee the accuracy of all goods we provide. You agree to purchase goods from our Site at your own risk.<br/><br/>

We reserve the right to modify, reject or cancel your order whenever it becomes necessary. If we cancel your order and have already processed your payment, we will give you a refund equal to the amount you paid. You agree that it is your responsibility to monitor your payment instrument to verify receipt of any refund.<br/><br/>

Payments<br/>
We accept the following payment methods on our Site:<br/><br/>

Credit Card.<br/>
When you provide us with your payment information, you authorize our use of and access to the payment instrument you have chosen to use. By providing us with your payment information, you authorize us to charge the amount due to this payment instrument.<br/><br/>

If we believe your payment has violated any law or these Terms and Conditions, we reserve the right to cancel or reverse your transaction.<br/><br/>

Shipping and Delivery<br/>
When you purchase goods from our Site, the goods will be delivered through one of the following methods:<br/><br/>

Standard delivery by post. Delivery takes 5 - 18 business days.<br/>
Delivery will take place as soon as reasonably possible, depending on the delivery method selected. Delivery times may vary due to unforseen circumstances. Please note that delivery times do not include weekends and statutory holidays.<br/><br/>

You will be required to pay delivery charges in addition to the price for the goods you purchase.<br/><br/>

If you purchase goods from us for delivery to a destination outside Canada your purchase may be subject to import duties and taxes applied by the destination country. You are responsible for paying any such duties or taxes. Please contact your local customs office for more information before making a purchase. We are not responsible for the payment of any such duties or taxes and are not liable for any failure by you to pay them.<br/><br/>

You are required to provide us with a complete and accurate delivery address, including the name of the recipient. We are not liable for the delivery of your goods to the wrong address or wrong person as a result of you providing us with inaccurate or incomplete information.<br/><br/>

Refunds<br/>
Refunds for Goods<br/>
Refund requests must be made within 30 days after receipt of your goods.<br/><br/>

We accept refund requests for goods sold on our Site for any of the following reasons:<br/><br/>

Good is broken;<br/>
Good does not match description; or<br/>
Good is the wrong size.<br/>
Refunds do not apply to the following goods:<br/><br/>

Opened Goods.<br/>
Returns<br/>
Returns can be made by mail. To return a good by mail, follow the following procedure:<br/>
Pack your goods and attach the return label that was included in your package. Go to your nearest post office and pay the necessary postage.<br/><br/>

Consumer Protection Law<br/>
Where the Business Practice and Consumer Protection Act, or any other consumer protection legislation in your jurisdiction applies and cannot be excluded, these Terms and Conditions will not limit your legal rights and remedies under that legislation. These Terms and Conditions will be read subject to the mandatory provisions of that legislation. If there is a conflict between these Terms and Conditions and that legislation, the mandatory provisions of the legislation will apply.<br/><br/>

Limitation of Liability<br/>
Wellness Supplements and our directors, officers, agents, employees, subsidiaries, and affiliates will not be liable for any actions, claims, losses, damages, liabilities and expenses including legal fees from your use of the Site.<br/><br/>

Indemnity<br/>
Except where prohibited by law, by using this Site you indemnify and hold harmless Wellness Supplements and our directors, officers, agents, employees, subsidiaries, and affiliates from any actions, claims, losses, damages, liabilities and expenses including legal fees arising out of your use of our Site or your violation of these Terms and Conditions.<br/><br/>

Applicable Law<br/>
These Terms and Conditions are governed by the laws of the Province of British Columbia.<br/><br/>

Dispute Resolution<br/>
Subject to any exceptions specified in these Terms and Conditions, if you and Wellness Supplements are unable to resolve any dispute through informal discussion, then you and Wellness Supplements agree to submit the issue first before a non-binding mediator and to an arbitrator in the event that mediation fails. The decision of the arbitrator will be final and binding. Any mediator or arbitrator must be a neutral party acceptable to both you and Wellness Supplements.<br/><br/>

Notwithstanding any other provision in these Terms and Conditions, you and Wellness Supplements agree that you both retain the right to bring an action in small claims court and to bring an action for injunctive relief or intellectual property infringement.<br/><br/>

Severability<br/>
If at any time any of the provisions set forth in these Terms and Conditions are found to be inconsistent or invalid under applicable laws, those provisions will be deemed void and will be removed from these Terms and Conditions. All other provisions will not be affected by the removal and the rest of these Terms and Conditions will still be considered valid.<br/><br/>

Changes<br/>
These Terms and Conditions may be amended from time to time in order to maintain compliance with the law and to reflect any changes to the way we operate our Site and the way we expect users to behave on our Site. We will notify users by email of changes to these Terms and Conditions or post a notice on our Site.<br/><br/>

Contact Details<br/>
Please contact us if you have any questions or concerns. Our contact details are as follows:<br/><br/>

______________________________________
katherine@kcbsoftware.com<br/>
1333 Haro Street, BC, V6E1G4<br/><br/>
+1-587-888-3825

You can also contact us through the feedback form available on our Site.<br/><br/>

Effective Date: 18th day of March, 2022<br/><br/>

©2002-2022 LawDepot.ca®<br/><br/>
	</div>
  )
}
