
 export const WebData =
 [
    {
       id: 1,
       name: 'Acai Immuno Defence capsules',
       image: 'https://res.cloudinary.com/kcb-software-design/image/upload/v1649892001/UsNatural/Acai-Immuno-Defence-pot-500x500_bbr9gk.jpg',
	   salesImage: 'https://res.cloudinary.com/kcb-software-design/image/upload/v1649892153/UsNatural/acai-a-plus-content-1_dgqof1.png',
	   price: 22.99,
	   productCode: 'SN099B',
	   priceID: 'price_1KowjrGn8i2V0O6vWqNidLLF',
	   ingredients: ['Acai Berry extract', 'Acai Berry Freeze Dried Powder', 'Organic Moringa Oleifera Powder',' Capsule:', 'Hydroxypropyl Methylcellulose (HPMC)', 'Beetroot Powder', 'Anti-caking Agent:', 'Magnesium Stearate (Magnesium salts of fatty acids) (vegetable source)', 'Pomegranate extract', 'Resveratrol Powder', 'Zinc Citrate Powder', 'Vitamin B6 Pyridoxine Hcl', 'Grape Seed Extract',' Vitamin B7 D-Biotin'],
       details: 'Acai Immuno Defence is a high-potency formulation, which combines Brazilian freeze-dried acai berry powder and extract with a range of other beneficial ingredients, including vitamins, minerals and herbs (such as zinc, vitamin B6, biotin, organic Moringa Oleifera, beetroot and more) to provide for approved EFSA health claims relating to immunity, energy, bones, hair, skin, nails and more (see below). These claims enable you to advertise and promote this food supplement for these proven benefits. This superfood supplement contains antioxidants (including anthocyanins), which offer cells protection against free radical damage and support the body’s own natural defence systems. Also rich in vitamins, minerals, phyto-nutrients and polyphenols, Acai Immuno Defence offers all-round support for energy, immunity, health and vitality - ideal for slimmers, athletes and diabetics.',
        
    },
    {
       id: 2,
       name: "Advanced Immunity Spectrum v1 capsules",
       image: 'https://res.cloudinary.com/kcb-software-design/image/upload/v1649892031/UsNatural/Advanced-Immunity-Spectrum-pot-500x500_kvfmoz.jpg',
       price: 21.99,
	   salesImage: 'https://res.cloudinary.com/kcb-software-design/image/upload/v1649892767/UsNatural/advanced-immunity-a-plus-1_f3gk6i.png',
	   productCode: ' IMUC60',
	   priceID: 'price_1KowkdGn8i2V0O6vBeWv2Eb0',
	   ingredients: ['Vitamin C', 'Acerola Cherry', 'Baobab Fruit Pulp', 'Astragalus Root', 'Black Pepper (Piperine (2.5mg))', 'Black Aged Garlic', 'Olive Leaf', 'Rosehip', 'Turmeric', 'Cayenne', 'Reishi Mushroom', 'Black Elderberry', 'Grapefruit Seed', 'Ginger Root', 'Lactobacillus Acidophilus (from 10 billion CFU/g)', 'Vitamin E', 'Zinc', 'Vitamin B6', 'Selenium', 'Vitamin D'],
       details: 'We have created a specific new immunity formula in response to the worldwide pandemic, in order to help people to simultaneously boost their immune system and fight off infection - both during this time of need and going forward. This formula is a tailored combination of both immunity ingredients and highly potent herbal anti-virals and anti-bacterials - formulated in combination with world-renowned naturopaths whom we supply, and based on protocols some Chinese doctors are using. It contains high dose vitamin C (512mg) from multiple sources, i.e. herbs, berries, plants and ascorbic acid PLUS 19 additional ingredients from herbs, berries, fruits, leaves, roots, mushroom, bulbs, vitamins and minerals. Vitamin C',
    
    },
    {
       id: 3,
       name: "Apple Cider Vinegar Complex v1 ",
       image: 'https://res.cloudinary.com/kcb-software-design/image/upload/v1649894061/UsNatural/Apple-Cider-Vinegar-Complex-pot-500x500_ddcygd.jpg',
       price: 19.99,
	   productCode: 'ACV-120',
	   salesImage: 'https://res.cloudinary.com/kcb-software-design/image/upload/v1649894068/UsNatural/apple-cider-a-plus-1_t7dtgn.png',
	   priceID: 'price_1KowlzGn8i2V0O6vMvM20PXa',
	   ingredients: ['Capsule Shell: Hydroxypropyl Methylcellulose (HPMC) (Vegetarian), Apple Cider Vinegar Powder', 'Anti-Caking Agent: Brown Rice Flour, Magnesium Stearate (Magnesium salts of fatty acids)', ' Cayenne (Capsicum Frutescens) Extract', 'Organic Black Pepper Powder', 'Turmeric Extract', 'Green Tea Extract', 'Ginger Root Extract', 'Chromium Picolinate Powder'],
       details: 'Apple Cider Vinegar Complex is a herbal weight management and digestive health combination. This food supplement has been formulated with a specialist blend of synergistic herbs and nutrients. Each capsule combines 400mg of apple cider vinegar powder with cayenne, ginger root, turmeric, green tea leaf, organic black pepper and chromium. Chromium is scientifically proven to contribute to normal macronutrient metabolism and to the maintenance of normal blood glucose levels - refer to the EFSA approved health claims section below.',
  
    },
    {
       id: 4,
       name: "Ashwagandha Botanical Complex v1",
       image: "https://res.cloudinary.com/kcb-software-design/image/upload/v1649894943/UsNatural/Ashwagandha-Botanical-Complex-pot-228x228_mzhowj.jpg",
       price: 20.99,
	   productCode: 'ASWG90',
	   salesImage: 'https://res.cloudinary.com/kcb-software-design/image/upload/v1649895036/UsNatural/Ashwagandha_v1_ASWG90_-_FP_3pp_DEFAULT-500x500_vn7hqq.png',
	   priceID: 'price_1KowmuGn8i2V0O6vxdQFR88V',
	   ingredients: ['Bulking agent: Brown Rice Flour', 'Capsule: Hydroxypropyl Methylcellulose (HPMC) (Vegetarian), Ashwagandha Extract (2.5% Withanolides), Rhodiola Rosea 3% Extract, Astragalus Root Extract 10:1', 'Anti-caking Agent: Magnesium Stearate (Magnesium salts of fatty acids)',  'Schisandra Chinensis', 'Maca Root Extract 10:1', 'Nicotinamide (Niacin)', 'Pantothenic Acid ( D-Calcium Panthonate) 90.1%', 'Liquorice Root Extract 10:1', 'Reishi Mushroom Extract 4:1', 'Black Pepper Extract (Piper Nigrum) 95% Piperine.', 'Korean Panax Ginseng 40:1 80% Ginsenocides', 'Turmeric Powder (Organic)', 'Vitamin B6 (Pyridoxal 5 Phosphate 68%)', 'Vitamin B12 Cyanocobalamin 1%', 'Gotu Kola Extract 4:1'],
       details: "Ashwagandha Botanical Complex is a synergistic combination of herbs, roots, reishi mushroom and vitamins, formulated to combat stress and adrenal fatigue, while also supporting the immune system, energy levels, mental performance and a general sense of calm, focus and vitality. The adrenal glands produce hormones that underpin the body's mechanisms for coping with physical and mental stress. Overtraining in athletes, chronic anxiety, worry, nervous tension and other factors associated with excessive cortisol release are linked to adrenal exhaustion and fatigue. Over-reliance on caffeine and other stimulants also taxes the adrenals. With potent adaptogens, this food supplement helps to balance hormones, allowing users to better manage their stress response on a day to day basis. These clever herbs help the body adapt to what it needs and regulate the system up or down, as required in the moment. The nature of adaptogenic herbs is to help a person “adapt” to their environment, as well as to normalise and regulate the body’s functioning. It combines important nutrients and nutraceuticals that support hormonal and adrenal health. Pantothenic acid, for example, is involved in the synthesis and release of adrenal hormones. Panax Ginseng also has an extensive history of use for the promotion of alertness and vitality, as well as counteracting fatigue, while Rhodiola is another powerful and fast-acting adaptogenic herb. It has been used traditionally to contribute to normal blood circulation, which in turn is associated with cognitive performance and reactivity. Ashwagandha Botanical Complex works best when combined with adequate rest and sound nutrition, and offers excellent support for those who wish to put their body back in balance, restore immunity and reinvigorate their energy levels to combat life's daily physical and mental stresses.",
    },
    {
       id: 5,
       name: "CherryBeet v2",
       image: "https://res.cloudinary.com/kcb-software-design/image/upload/v1649895523/UsNatural/CherryBeet-pot-500x500_twjoay.jpg",
       price: 19.99,
	   productCode: 'BRMC60',
	   salesImage: 'https://res.cloudinary.com/kcb-software-design/image/upload/v1649895535/UsNatural/CherryBeet_v2_BRMC60_-_3pp_DEFAULT-500x500_qoriwo.png',
	   priceID: 'price_1KowrfGn8i2V0O6vE2UGRd5i',
	   ingredients: ['Beetroot Extract', 'Montmorency Cherry Extract', 'Capsule Shell: Hydroxypropyl Methylcellulose (HPMC)', 'Anti-caking Agent: Magnesium Stearate (Magnesium salts of fatty acids) ', 'Black Pepper Powder', 'Turmeric Extract (95% Curcumin)', 'Vitamin B6 Pyridoxine Hcl'],
       details: 'CherryBeet combines beetroot powder and extract (10:1), with Montmorency (sour) cherry powder and extract (10:1), to provide a concentrated formula - 4500mg per capsule. These superfoods are then complimented by the addition of three nutritive allies in the same formula - vitamin B6, black pepper powder and turmeric extract (95% curcumin) - to provide additional support for the reduction of tiredness and fatigue, energy-yielding metabolism, immunity, red blood cell formation, the nervous system, hormonal activity, protein and glycogen metabolism and more. This food supplement is high in bioavailable antioxidants (including anthocyanins) and contains non-irritating iron, while the black pepper increases the absorption and utilisation of the beneficial ingredients in the formula. Combination cherry and beetroot capsules are particularly popular with athletes, because of the high levels of dietary nitrate present in beetroot, the anti-inflammatory agents present in Montmorency cherry and the high levels of antioxidants present in both. Allied with vitamin B6, black pepper powder and turmeric extract, this supplement offers those who place high demands on their body a winning combination for high energy levels, stamina, immunity and general well-being.',
      
    },
    {
       id: 6,
       name: "COL-Clear A v4 capsules",
       image: "https://res.cloudinary.com/kcb-software-design/image/upload/v1649895959/UsNatural/COL-Clear-A-_LEGAL_-pot-500x500_v2wrzv.jpg",
       price: 19.99,
	   productCode: '052L-00',
	   salesImage: 'https://res.cloudinary.com/kcb-software-design/image/upload/v1649895967/UsNatural/COL-Clear_A_v4_052L-00_-_FP_3pp_DEFAULT-500x500_cm29wq.png',
	   priceID: 'price_1KowsqGn8i2V0O6vkSEaVujT',
	   ingredients: [ 'Rhubarb Powder',' Capsule: Hydroxypropyl Methylcellulose (HPMC), Aloe Vera Extract', 'Cayenne Powder', 'Burdock Root Powder', 'Rhubarb Root Extract', 'Sugar Beet Fibre Powder', 'Cloves Bud Powder', 'Anti-caking Agent: Magnesium Stearate', 'Barberry Bark Extract',' Fennel Seed Powder', 'Liquorice Root Powder', 'Dandelion Root Extract', 'Ginger Root Extract'],
       details: 'COL-Clear is a well-known herbal colon cleanser and bowel support combination, based on a formula by master herbalist, Dr. John R. Christopher. It is available in 2 versions - this COL-Clear A and COL-Clear B. Popular with colonic hydrotherapists, COL-Clear A contains a range of active herbal ingredients specifically selected to contribute to an increase in faecal bulk and normal bowel function. The sugar beet fibre in this formula, in particular, contributes to an increase in faecal bulk in two ways: the insoluble components of the fibre increase faecal bulk by absorbing water in the large intestine, while the soluble components are fermented by bacteria in the large intestine leading to an increase in bacterial mass. As such, this source of fibre may have a beneficial physiological effect for people who want to improve or maintain normal bowel function. The ingredients help to cleanse the intestinal tract, soften the stool, stimulate the liver and improve peristalsis. This, in turn, helps to produce bowel movements and expel layers of old encrusted mucus and faecal matter that may have accumulated over time. They also act to gently cleanse, stimulate and tone the bowel wall, supporting a move towards unassisted bowel movements.',
       
    },
    {
       id: 7,
       name: "Fibre and Full v4 ",
       image: "https://res.cloudinary.com/kcb-software-design/image/upload/v1649897235/UsNatural/Fibre-and-Full-pot-500x500_tsa7cn.jpg",
       price: 18.99,
	   productCode: 'SN040',
	   salesImage: 'https://res.cloudinary.com/kcb-software-design/image/upload/v1649897241/UsNatural/ROUND_POT_-_Fibre_Full_v4_SN040_on_3pp_label-500x500_blxmqm.png',
	   priceID: 'price_1KowtqGn8i2V0O6vGTuaZ42s',
	   ingredients: ['Psyllium Husk powder (Plantago ovata)', 'Glucomannan', 'Sugar Beet', 'L-Glutamine', 'Inulin (Fructo-Oligosaccharides)', 'Fennel Seed', 'Peppermint Leaf', 'Ginger Root', 'Bacteria blend: Lactobacillus Acidophilus, Bifidobacterium Bifidum', 'Sweetener: Steviol glycosides (stevia leaf extract)'],
       details: 'Fibre & Full is an all-in-one dietary fibre based colon cleanser, detoxification and weight loss supplement in a tasty, easy-to-take powder form - a natural solution for constipation, body waste cleansing and shape-up support with a special combination of psyllium husks, glucommanan, sugar beet fibre, L-Glutamine, prebiotics, probiotics, gut-soothing herbs and stevia leaf extract. Due to its wide variety of nutrients and high fibre content, it is ideal for long-term use as a bowel cleanser and detoxifier, cell wall repair agent and an overall colon maintenance formula. It also contributes to weight loss. Specifically formulated to contribute to an increase in faecal bulk, as well as encourage regular bowel movements, better digestion of foods, re-population of beneficial bowel bacteria, stable blood sugar levels and appetite control. Sugar beet fibre, in particular, contributes to an increase in faecal bulk in two ways: the insoluble components of the fibre increase faecal bulk by absorbing water in the large intestine, while the soluble components are fermented by bacteria in the large intestine leading to an increase in bacterial mass. As such, this source of fibre may have a beneficial physiological effect for people who want to improve or maintain a normal bowel function.',
      
    },
    {
       id: 8,
       name: "GreeNourish Complete v3 organic pdr",
       image: "https://res.cloudinary.com/kcb-software-design/image/upload/v1649897618/UsNatural/GreeNourish-pot-228x228_bqdsuz.jpg",
       price: 29.99,
	   productCode: 'SN105',
	   salesImage: 'https://res.cloudinary.com/kcb-software-design/image/upload/v1649897628/UsNatural/ROUND_POT_-_Greenourish_SN105_on_3pp_label-500x500_n0tvaa.png',
	   priceID: 'price_1KowvHGn8i2V0O6vHUUeTWfR',
	   ingredients: [ 'Pre-Sprouted Activated Barley',' Lucuma Fruit', 'Linseed (Flaxseed)',' Wheat Grass',' Quinoa', 'Barley Grass', 'Acai Berry', 'Baobab Fruit Pulp', 'Seagreens® Kelp (Ascophyllum)', 'Spirulina', 'Turmeric', 'Alfalfa', 'Carrot', 'Bilberry Fruit', 'Spinach Leaf', 'Barley Grass Juice', 'Wheat Grass Juice', 'Beet',' Acerola Cherry Extract', 'Chlorella (Broken Cell Wall)', 'Nettle', 'Tomato', 'Bilberry Extract', 'Blueberry', 'Cranberry',' Green Cabbage', 'Kale', 'Parsley', 'Kale Sprout', 'Broccoli Sprout', 'Reishi Mushroom', 'Cordyceps Mushroom', 'Shiitake Mushroom', 'Cauliflower Sprout', 'Maitake Mushroom', 'Enzyme Blend*'],
       details: 'GreeNourish Complete is no ordinary green shake - this Soil Association organic, high-fibre combination contains 35 green foods, vegetables, fruits, berries, herbs, sprouts, mushrooms and seeds plus bio-active enzymes - organic vegan nutrition made easy, with naturally high food form vitamin C content, as well as plant protein. A great all-round supplement to support immunity, digestion (bulk), energy levels, cleansing, detoxification and alkalising of the body.',
      
    },
]


