import React, {Suspense, lazy}from 'react';
import { BrowserRouter  as Router, Route, Switch} from 'react-router-dom';

import LandingPage from './Pages/LandingPage';

import Products from './Pages/Products';
import { CartProvider } from './config/CartContext';
import ProductDetails from './Pages/ProductDetails';
import Privacy from './Pages/Privacy';
import Terms from './Pages/Terms';
import Contact from './Pages/Contact';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import "@fontsource/actor";
import './App.css';
import Cart from './Pages/Cart';
import success from './Pages/success';
import CartEvalon from './Pages/cartEvalon';
import Checkout from './Pages/Checkout';


function App() {
  return (
	<CartProvider>
	  <Router>
		<Navbar/>
		{/* <Redirect to="/" /> */}
		{/* </Route> */}
		{/* <Suspense fallback={<div>LOADING</div>}> */}
		<Switch>
	<Route path='/' exact component = {LandingPage}/>
	<Route path='/products' exact component = {Products}/>
	<Route path='/productDetails' exact component = {ProductDetails}/>
	<Route path='/contact' exact component = {Contact}/>
	<Route path='/cart' exact component = {Cart}/>
	<Route path='/checkout' exact component = {Checkout}/>
	<Route path='/privacypolicy' exact component = {Privacy}/>
	<Route path='/terms' exact component = {Terms}/>
	<Route path='/cartEv' exact component = {CartEvalon}/>
	<Route path='/success' exact component = {success}/>
	<Route path='/contact' exact component = {Contact}/>

	
	{/* </Suspense> */}
		</Switch>
		<Footer/>
	</Router>
	</CartProvider>
  );
}

export default App;
