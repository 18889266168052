import React, {createContext, useEffect, useState, useContext} from 'react';
import { Link } from 'react-router-dom';
import '../css/products.css';
import { CartContext } from '../config/CartContext';
import { LazyLoadImage } from 'react-lazy-load-image-component';
// import { CartContext } from '../config/CartContext';

export default function ProductDetails(props) {
	const data = props.location.state;
	
	const { addItemToCart } = useContext(CartContext);
	const [ingredients, setIngredients] = useState([]);
	
	useEffect(()=>{
		setIngredients(data.ingredients);
	},)

  return (
<body>
<LazyLoadImage alt="" src='https://res.cloudinary.com/kcb-software-design/image/upload/v1649900268/UsNatural/natural-zomed-in-2022-03-01-02-17-35-utc_t8phmt.jpg' className="square"/>
	<div className="productContainer">
	{/* <Link to='/'><button>Back To Home</button></Link> */}
		{/* <div className='productDetails'>Product Details </div> */}
			<div className="productName">{data.name}</div><br/>
			<div className="productPrice">${data.price} CAD</div><br/><br/>
		<img className="imageFirst" src={data.image} alt=""/><br/>
		
			<button  className="addToCartButton" onClick={()=> addItemToCart(data.id)}>Add to Cart</button>
			<div className='details'>{data.details}</div>
			<img className="image" src={data.salesImage} alt=""/><br/>
			<div className="ingredientContainer">
				Ingredients:<br/><br/>
				{ingredients.map((item, index)=>{
				return(	
				
					<li key={index}>
					<span>{item}</span>
					</li>
				
				)})}
			</div>
		



	</div>
</body>
  )
}
