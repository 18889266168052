import * as firebase from 'firebase/app'
import { getApp, getApps } from 'firebase/app';

const firebaseConfig = {
	apiKey: "AIzaSyA7OGvksT5uTaPUG3Xd7mdFtCUMaDzdziM",
	authDomain: "wellness-supplements.firebaseapp.com",
	projectId: "wellness-supplements",
	databaseURL: "https://wellness-supplements-default-rtdb.firebaseio.com/",
	storageBucket: "wellness-supplements.appspot.com",
	messagingSenderId: "999202176693",
	appId: "1:999202176693:web:7388faf2432fc0a40b08fc",
	measurementId: "G-VSW36LQ9TH"
  };


let app;
if(!getApps().length)
{
 app = firebase.initializeApp(firebaseConfig);
}  
else{
    app = getApp();
}
export {app};