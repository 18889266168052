import React, {createContext, useState, useEffect} from 'react';
import * as firebase from 'firebase/app'
import {getDatabase, ref, child, get } from 'firebase/database';
import { getProduct } from './cartData';
import { app } from './firebase';
const itemsFromLocalStorage = JSON.parse(localStorage.getItem('cart')|| '[]');
export const CartContext = createContext();


  const dbRef = ref(getDatabase(app));
  let count = 0;
 
 
 export function CartProvider(props) {
	
  const [objectArray, setObjectArray]= useState()
													
											
  const [object, setObject] = useState();
  const [gotKey, setGotKey] = useState();
  const [items, setItems] = useState(itemsFromLocalStorage);
  useEffect(()=>{
	  if(count<3){
		  getObjects()
	  }
	  if(items.length > 0)
	  {
		  localStorage.setItem('cart', JSON.stringify(items));
	  }
 
  },[items, gotKey])
 
  const getObjects = async()=> {
		  const dbRef = ref(getDatabase(app));
		  const tryy= get(child(dbRef, `cartDataSheet`)).then((snapshot)=>{
			  setGotKey(snapshot.val());
			  return snapshot.val();
			  
		  }).catch((error)=>{
			  console.log(error);
		  })
		  await tryy;
		  count++
		  if(count <= 0){
		  getObjects();
	 	 }
		 if(count> 0){
			  return  tryy;
		  }

	  }
	  const getDataProduct=(id)=>{
		return gotKey.find((e)=>(e.id == id));
	  }
	
  function addItemToCart(id) {

	  const product = getDataProduct(id);

	  const existingCartItemIndex = items.findIndex((item) => (item.id == id));

	  
	  if(existingCartItemIndex < 0) {
			
			  setItems((prevItems) => {
          return [...prevItems, {
              id,
              qty: 1,
              product,
              totalPrice: product.price 
          }];})

      }
      else { 
		setItems((prevItems) => { 
		  return prevItems.map((item,) => {
		if(item.id == id ) {
		item.qty = item.qty + 1;
		item.totalPrice += product.price;
		
		}
		return item;	
		});
     });
		}
    };
//}
	function removeItemFromCart(id){
		const product = getDataProduct(id);
		// const product = getProduct(id);
		
			setItems((prevItems)=>{
			return prevItems.map((item) => {
				if(item.qty > 0){

					if(item.id == id) {
						item.qty = item.qty - 1;
						item.totalPrice -= product.price;		
					}
					}
					if(item.qty<= .5){
						setItems(items.filter(item=> item.id !== id));

					}
					return item;
				})
			});
		
	}
function getItemsCount() {

	let totalitemcount = items.reduce((sum, item)=>(sum + item.qty),0);
	if(totalitemcount > 0){
		return totalitemcount;
	}
  }

  function getTotalPrice() {

	let Itemtotalprice = items.reduce((sum, item)=>(sum + item.totalPrice),0);
	if(Itemtotalprice <= 0 || Itemtotalprice == null)
	{
		return 0;
	}
	return Itemtotalprice;

  }  

  return (
    <CartContext.Provider 
      value={{items, setItems, getItemsCount, addItemToCart, removeItemFromCart, getTotalPrice, localStorage}}>
      {props.children}
    </CartContext.Provider>
  );
}
