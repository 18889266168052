import React from 'react'
import '../css/footer.css';
import * as AiIcons from 'react-icons/ai';

import { Link } from 'react-router-dom';

export default function Footer() {
	const scrollToTop = () => {
		window.scrollTo({
		  top: 0,
		  behavior: "smooth"
		});
	  };
  return (
	<div className="footer">
	
	<div className = "big-container">
		<div className="link-container">
			<div className="generic_links containers">
			{/* <Link to={'/home'} onClick={scrollToTop} className='home_link links'>Home</Link><br/>
			<Link to={'/contact'} onClick={scrollToTop} className='contact_link links'>Contact Us</Link><br/>
			<Link to={'/advantage'} onClick={scrollToTop} className='about_link links'>Advantage</Link><br/>
			<Link to={'/products'} onClick={scrollToTop} className='about_link links'>Advantage</Link><br/> */}
			{/* Us Natural<br/>
			Vancouver BC, Canada<br/>
			V6E1G4<br/> */}
			<Link to={"/"} onClick={()=> scrollToTop()}style={{ textDecoration: 'none' }}><img src='https://res.cloudinary.com/kcb-software-design/image/upload/v1650419188/Wellness%20Supplements/supplementLogo_vbnqhb.png' alt='' className="title logoFooter"/></Link>
	
	
			</div>
			
		<div className="term_links containers">
		
		<Link to={"/terms"} onClick={scrollToTop} className='terms'>Terms & Conditions</Link><br/>
		<Link  to ={"/privacypolicy"} onClick={scrollToTop} className="privacy">Privacy Policy</Link><br/>
		<Link  to ={"/contact"} onClick={scrollToTop} className="privacy">Contact Us</Link>
		</div>
		</div>
<div className="copyright">
<img src="https://res.cloudinary.com/kcb-software-design/image/upload/v1666848316/cc_yhw6nf.png" alt="credit card logos"/>
<p>Medical Disclaimer<br/><br/></p>
<div className='disclaimer'>Statements made on this website have not been evaluated by Health Canada. These products are not intended to diagnose, treat, cure, or prevent any disease. Information provided by this website or this company is not a substitute for individual medical advice.<br/></div>
	Developed By KCB Software & Design<br/><br/><AiIcons.AiOutlineCopyright/>Copyright 2022 Us Natural</div>
	</div>
	</div>
  )
}
